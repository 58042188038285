import { WaliLetrasResult } from './../core/shared/state/models/wali-letras-result';
import { WaliLetrasInfo } from './../core/shared/state/models/wali-letras-info.model';
import { WaliLetras } from './../core/shared/state/models/wali-letras.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable()
export class WaliLetrasService {
    show = false;
    baseUrl = environment.baseApi + '/api/WaliLetras';
    timer = {
        interval: null,
        startTime: null,
        currentSeconds: null,
        fullSeconds: 300,
    };
    timerEnd = false
    paused = false
    s_has_finished = new Subject<boolean>();
    has_finished = this.s_has_finished.asObservable();

    s_change_button = new Subject<boolean>();
    change_button = this.s_change_button.asObservable();
    constructor(private http: HttpClient) { }
    public isFinished(idUser){
        return this.http.get<any>(this.baseUrl + "/finished/" + idUser + "/" + moment().week());
    }
    public review(idUser, idSchool){
        return this.http.get<WaliLetrasResult[]>(this.baseUrl + "/review/" + idUser + "/" + idSchool + "/" + moment().week());
    }
    public getPalabras(week, level){
        return this.http.get<WaliLetras>(this.baseUrl + "/" + week + "/" + level);
    }
    public addAnswer(obj: WaliLetrasInfo){
        return this.http.post<WaliLetrasInfo>(this.baseUrl + "/add", obj);
    }
    public start(result: WaliLetrasResult = {
        IdUser: 0,
        week: 0,
        Score: 0,
        Finished: false
    }) {
        return this.http.post<WaliLetrasResult>(this.baseUrl + "/start/", result);
    }

    public finish(result: WaliLetrasResult = {
        IdUser: 0,
        week: 0,
        Score: 0,
        Finished: true
    }) {
        return this.http.post<WaliLetrasResult>(this.baseUrl + "/finish/", result);
    }
    public getActivityDay() {
        return this.http.get(this.baseUrl + "/getActivityDay");
    }

    public startTimer() {
        // this.timer.fullSeconds = 300000000000000; // Leo
        if (!this.timer.startTime) {
            this.timer.startTime = new Date().getTime();
        }
        this.timer.currentSeconds = 0;
        this.timerEnd = false;
        this.timer.interval = setInterval(() => {
            if (!this.paused) this.timer.currentSeconds = this.getSeconds();
            if (this.timer.fullSeconds != null && this.timeExpired() && !this.timerEnd && !this.paused) {
                this.stopTimer();
            }
        }, 100);
    }

    public getSeconds() {
        let now = moment().valueOf();
        return (now - this.timer.startTime) / 1000;
    }
    public getTime(){
        const time = Math.round(this.timer.fullSeconds - this.timer.currentSeconds);
        return Math.max(0, time);
    }
    public timeExpired() {
        return this.getSeconds() >= this.timer.fullSeconds;
    }

    public stopTimer() {
        this.timerEnd = true;
        this.paused = true;
        this.changeHasFinished(true)
        clearInterval(this.timer.interval);
    }
    pauseTimer() {
        this.paused = true;
    }

    restartTimer() {
        this.paused = false;
    }
    public getFullSeconds() {
        return this.timer.fullSeconds;
    }

    public getCurrentSeconds() {
        return this.timer.currentSeconds;
    }
    resetTimer() {
        this.timerEnd = false;
        this.timer = {
            interval: null,
            startTime: null,
            currentSeconds: null,
            fullSeconds: null,
        };
    }
    changeHasFinished(hasFinished: boolean) {
        this.s_has_finished.next(hasFinished);
    }
    changeButton(changeButton: boolean) {
        this.s_change_button.next(changeButton);
    }
    resetVariables() {
        clearInterval(this.timer.interval);
        this.show = false;
        this.timer = {
            interval: null,
            startTime: null,
            currentSeconds: null,
            fullSeconds: 300,
        };
        this.timerEnd = false
        this.paused = false
        
    }
}
