import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { User, DataQuery, DataService, createUser } from 'app/core/shared/state';
import { UiService } from '@fuse/components/spinner/ui.service';
import { RepositoryService } from 'app/core/repository/repository.service';
import { SharedService } from 'app/core/shared/shared.service';
import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { SchoolService } from 'app/services/school.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'student-info',
  templateUrl: './student-info.component.html',
  styleUrls: ['./student-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class StudentInfoComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  studentInfoForm: UntypedFormGroup;
  student: any;
  user: User;
  students = new Array();
  colors: string[] = [
    'var(--naranja)', // naranja
    'var(--verde)', // verde
    'var(--verdeAzulado)', // verde azulado
    'var(--amarillo)', // amarillo
    'var(--fucsia)' // fucsia
  ];
  counter: number = 0;
  studentList = new Array();
  name: string;
  userName: string;
  levels: any[];
  level: string;
  minDate: Date;
  maxDate: Date;
  mobile: boolean = false;
 iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
  indexSelected: number;
  errorMessage: string;
  regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u

  constructor(
    private appService: AppService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private renderer: Renderer2,
    private dataQuery: DataQuery,
    private _ui: UiService,
    private _repositoryService: RepositoryService,
    public _sharedService: SharedService,
    private _dataService: DataService,
    private dateAdapter: DateAdapter<Date>,
    private toolbarService:ToolbarService,
    public schoolService: SchoolService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this._ui.spin$.next(true);
    this.mobileDetect = this.appService.mobileDetect();
    this.errorMessage = "";
    this.dateAdapter.setLocale('es');
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.mobile = this._sharedService.isMobile();
  }

  ngAfterViewInit(){
    const matToolbarRef = this.toolbarService.getToolbarRef();
    if (matToolbarRef) {
        setTimeout(() => {
            this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
        }, 0);
    }
	}

  ngOnInit(): void {
    this.mobile = this._sharedService.isMobile();
    this.user = this.dataQuery.user;
    if (this.user == null) {
      this._router.navigate(['./login']);
      return;
    }
    this.initializeForm();
    this.studentInfoForm.patchValue({email: this.user.email});
    this.studentList.push(this.counter);
    this.dateRange();
    this._ui.showSpinner();
    this._repositoryService.get(environment.baseApi + '/api/Course/')
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response: any) => {
      this._ui.stopSpinner();
      this.levels = response;
    },
    error => {
      this.errorMessage = this._sharedService.getError(error);
      this._ui.stopSpinner();
    });
  }

  initializeForm() {
    this.studentInfoForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(this.regExp)]],
      level: ['', Validators.required],
      userName: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25),Validators.pattern(/^[0-9A-Za-zñÑ\.]+$/)]],
      dateOfBirth: null,
      email: ['', Validators.required]
    });
  }
  onSelectionChange(event) {
    this.level = event.value;
  }

  dateRange() {
    const eightYearsAgo = new Date().getFullYear() - 8;
    const eighteenYearAgo = new Date().getFullYear() - 18;
    this.minDate = new Date(eighteenYearAgo, 0, 1);
    this.maxDate = new Date();
  }

  getBackgroundColor(i, elem) {
    this.renderer.setStyle(elem, 'background-color', this.colors[i]);
  }

  add(callback = null) {
    if (this.students.length < 5) {

      for(let user of this.students) {
        if (user.userName.toUpperCase() == this.studentInfoForm.value.userName.toUpperCase() && user.counter != this.indexSelected + 1) {
          this.errorMessage = this._translateService.instant('AUTHENTICATION.ERROR.USERNAME');
          return
        }
      }
      
      if (this.studentInfoForm.valid) {
  
        this.student = Object.assign({}, this.studentInfoForm.value);

        Promise.all([
          this._sharedService.validateUser(this.student.userName, this.user.email, false)
        ]).then(
          ([response]: [any]) => {
            this._ui.stopSpinner();
                if (response == null) {
              if (this.indexSelected != null && this.students[this.indexSelected] != undefined) {

                this.students[this.indexSelected].name = this.student.name;
                this.students[this.indexSelected].level = this.student.level;
                this.students[this.indexSelected].userName = this.student.userName;
                this.students[this.indexSelected].dateOfBirth = this.student.dateOfBirth;
                this.students[this.indexSelected].email = this.user.email;
                this.students[this.indexSelected].counter = this.counter;
                this.indexSelected = null;
              }
              else {
                this.counter++;
                this.student.counter = this.counter;
                this.students.push(this.student);
                this.studentList.push(this.counter);
                this.resetFormValues();
              }
              if(callback) callback();
            }
            else
              this.errorMessage = this._sharedService.getError(response);
          }).catch(error => {
            this.errorMessage = this._sharedService.getError(error);
            this._ui.stopSpinner();
          });;

        if (this.students.length === 5) {
          this.studentInfoForm.disable();
        }
      }
    }
  }

    loadStudent(index) {
        if (this.students.length > 0 && this.students[index] != undefined) {
            let student = this.students[index];
                this.indexSelected = index;
                this.studentInfoForm.setValue({
                    name: student.name,
                    level: student.level,
                    userName: student.userName,
                    dateOfBirth: student.dateOfBirth,
                    email: this.user.email,
                });
            this.add()
    }
    else if (this.students.length > 0 && this.students[index] == undefined) {
        this.indexSelected = index;
        this.resetFormValues();
        this.student = Object.assign({}, this.studentInfoForm.value);
    }
  }
  resetFormValues(){
    this.studentInfoForm.reset();
    this.initializeForm();
    this.studentInfoForm.patchValue({ email: this.user.email });
    this.errorMessage = "";
  }

  finish(event) {
    this.studentInfoForm.markAllAsTouched();
    if(this.studentInfoForm.invalid){
        return;
    }
    this.add(() => {
      let students = new Array()
      this.students.forEach(student => {
        students.push(createUser(student))
      });
      this._dataService.saveDataStudents(this.students);
      
      this._router.navigate(['/free-trial/family-password']);
  
    });
  }


  hasError() {
    return this._sharedService.hasError(this.errorMessage);
  }

}
